import Router from 'next/router';
import fetch from './Fetch';
import { defaultProps } from '../stores/Observables';
import { startsWith, get } from 'lodash';
import config from '../config';

export default class Session {
  static async init({ req, query }) {
    const props = defaultProps(req ? req.headers.cookie : document.cookie);
    if (!props.authToken) {
      console.log('No token provided');
      return {};
    }

    //
    //  Real user
    //

    // cache user on client only.
    if (!props.user || !process.browser) {
      const rsp = await this.me(props.authToken);
      if (rsp.ok) {
        const body = await rsp.json();
        props.user = body;
      } else {
        console.log('Failed to retrieve user');
        return {};
      }
    }

    // set pending brokerage
    if (props.user.pendingBrokerages) {
      props.pendingBrokerage = props.user.pendingBrokerages[0];
    }

    // set pending access
    if (props.user.pendingUserGrants) {
      props.pendingAccess = props.user.pendingUserGrants[0];
    }

    // set active brokerage
    if (props.user.brokerages && query.brokerageUuid) {
      props.activeBrokerage = props.user.brokerages.find(
        (b) => b.uuid === query.brokerageUuid
      );
    }

    //
    //  Switched user
    //

    // get switched user
    if (query.userUuid && query.userUuid !== props.user.uuid) {
      if (!props.currentUser || props.currentUser.uuid !== query.userUuid) {
        const rsp = await this.me(props.authToken, query.userUuid);
        if (rsp.ok) {
          const body = await rsp.json();
          props.currentUser = body;
        }
      }
    }

    // if no user default to real user
    if (!props.currentUser) {
      props.currentUser = props.user;
    }

    // set billing
    props.billing = props.currentUser.billing;

    return props;
  }

  static redirect({
    req,
    res,
    query,
    fromPathname,
    fromAsPath,
    toPathname,
    toAsPath,
    comparePathnames = true,
  }) {
    // already on page to redirect to
    if (comparePathnames && fromPathname === toPathname) {
      console.log(`Redirected to ${fromAsPath}`);
      return {};
    }

    // add where you came from if going to login
    if (toPathname === '/auth/login') {
      query.return_uri = (req && req.originalUrl) || fromAsPath;
    }

    // do universal redirect
    const queryString = Object.keys(query)
      .map((k) => `${k}=${query[k]}`)
      .join('&');
    if (res) {
      res.redirect(
        `${toAsPath}${
          query.return_uri ? `?return_uri=${query.return_uri}` : ''
        }`
      );
      res.end();
    } else {
      Router.push(`${toPathname}?${queryString}`, toAsPath);
    }

    // block further session processing
    console.log(`Redirecting to ${toAsPath}`);
    return {};
  }

  static async initFromLink({ req, res, query, pathname, asPath }) {
    const props = defaultProps(req ? req.headers.cookie : document.cookie);
    const rsp = await this.getPackageLink(props.authToken, query.packageSlug);
    if (rsp.ok) {
      const body = await rsp.json();
      props.user = props.currentUser = body.currentUser;
      props.packageDetail = body.package;
      props.packageActiveContributor = body.activeContributor;
      return props;
    } else {
      return this.redirect({
        req,
        res,
        query,
        fromPathname: pathname,
        fromAsPath: asPath,
        toPathname: '/error/no-such-property',
        toAsPath: '/error/no-such-property',
      });
    }
  }

  static async initFromApp({ req, res, query, pathname, asPath }) {
    const props = await this.init({ req, res, query, pathname });
    if (!props.user) {
      console.log('No user from session');
      return this.redirect({
        req,
        res,
        query,
        fromPathname: pathname,
        fromAsPath: asPath,
        toPathname: '/auth/login',
        toAsPath: '/auth/login',
      });
    }
    // access outside of session scope
    if (
      props.user.provider === 'package' &&
      pathname !== '/app/packages/detail'
    ) {
      console.log('Access outside of session scope');
      return this.redirect({
        req,
        res,
        query,
        fromPathname: pathname,
        fromAsPath: asPath,
        toPathname: '/auth/login',
        toAsPath: '/auth/login',
      });
    }

    // switching to user without access
    if (
      !props.currentUser ||
      (query.userUuid && props.currentUser.uuid !== query.userUuid)
    ) {
      console.log('Missing switched user');
      query.userUuid = props.user.uuid;
      return this.redirect({
        req,
        res,
        query,
        fromPathname: pathname,
        fromAsPath: asPath,
        toPathname: '/app/packages',
        toAsPath: `/app/users/${props.user.uuid}/packages`,
        comparePathnames: false,
      });
    }

    // set user context if not present
    query.userUuid = props.currentUser.uuid;

    // compute outstanding state
    const isRealUser =
      props.user.provider !== 'package' &&
      !!props.user.uuid &&
      props.user.uuid === props.currentUser.uuid;
    const outstanding = {
      brokerage: !!props.pendingBrokerage,
      access: !!props.pendingAccess,
      confirm: !props.currentUser.confirmed && !props.user.impersonating,
      password: props.currentUser.anonymous && !props.user.impersonating,
      qualify: !props.user.qualifiedRole && !props.user.impersonating,
    };
    const isBrokerageAdmin =
      get(props.activeBrokerage, 'offices', []).some(
        (o) => o.role === 'Admin'
      ) || get(props.activeBrokerage, 'isAdmin', false);
    const isSuperUser = props.user.role === 'superuser';

    if (
      (!isSuperUser && startsWith(pathname, '/app/admin')) ||
      (!isBrokerageAdmin && startsWith(pathname, '/app/brokerages')) ||
      (!outstanding.brokerage && pathname === '/app/activate') ||
      (!outstanding.access && pathname === '/app/access') ||
      (!outstanding.confirm && pathname === '/app/confirm') ||
      (!outstanding.password && pathname === '/app/reset') ||
      (!outstanding.qualify && pathname === '/app/welcome')
    ) {
      return this.redirect({
        req,
        res,
        query,
        fromPathname: pathname,
        fromAsPath: asPath,
        toPathname: '/app/authed',
        toAsPath: '/app/authed',
      });
    }
    // blocked pages can only be experienced by real user
    else if (isRealUser && pathname !== '/app/packages/detail') {
      // confirm account
      if (outstanding.confirm) {
        if (pathname !== '/app/confirm') {
          console.log('User is not confirmed');
          return this.redirect({
            req,
            res,
            query,
            fromPathname: pathname,
            fromAsPath: asPath,
            toPathname: '/app/confirm',
            toAsPath: '/app/confirm',
          });
        }
      }
      // password reset
      else if (outstanding.password) {
        if (pathname !== '/app/reset') {
          console.log('User must reset password');
          return this.redirect({
            req,
            res,
            query,
            fromPathname: pathname,
            fromAsPath: asPath,
            toPathname: '/app/reset',
            toAsPath: '/app/reset',
          });
        }
      }
      // pending brokerage
      else if (outstanding.brokerage) {
        if (pathname !== '/app/activate') {
          console.log('User has pending brokerage account');
          return this.redirect({
            req,
            res,
            query,
            fromPathname: pathname,
            fromAsPath: asPath,
            toPathname: '/app/activate',
            toAsPath: '/app/activate',
          });
        }
      }
      // pending access request
      else if (outstanding.access) {
        if (pathname !== '/app/access') {
          console.log('User has pending access request');
          return this.redirect({
            req,
            res,
            query,
            fromPathname: pathname,
            fromAsPath: asPath,
            toPathname: '/app/access',
            toAsPath: '/app/access',
          });
        }
      } else if (pathname === '/app/upgrade') {
        return props;
      }

      // user qualifying request
      else if (outstanding.qualify) {
        if (pathname !== '/app/welcome') {
          console.log('User is new');
          return this.redirect({
            req,
            res,
            query,
            fromPathname: pathname,
            fromAsPath: asPath,
            toPathname: '/app/welcome',
            toAsPath: '/app/welcome',
          });
        }
      }
    }
    // switched user
    else {
      // disallow interstitials and viewing other user's password or security unless user is an admin impersonating
      if (
        pathname === '/app/activate' ||
        pathname === '/app/access' ||
        pathname === '/app/confirm' ||
        pathname === '/app/reset' ||
        pathname === '/app/welcome' ||
        (!props.user.impersonating &&
          (pathname === '/app/settings/password' ||
            pathname === '/app/settings/security'))
      ) {
        return this.redirect({
          req,
          res,
          query,
          fromPathname: pathname,
          fromAsPath: asPath,
          toPathname: '/app/authed',
          toAsPath: '/app/authed',
        });
      }
    }

    // default behavior is to go to package list
    if (pathname === '/app/authed') {
      query.userUuid = props.user.uuid;
      return this.redirect({
        req,
        res,
        query,
        fromPathname: pathname,
        fromAsPath: asPath,
        toPathname: '/app/packages',
        toAsPath: `/app/users/${props.user.uuid}/packages`,
      });
    }

    return props;
  }

  static me(token, userUuid) {
    return fetch(
      `${config.apiHost}/api/v1${userUuid ? '/users/' + userUuid : '/me'}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        redirectUnauthenticated: false,
      }
    );
  }

  static access(token) {
    return fetch(`${config.apiHost}/api/v1/users`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      redirectUnauthenticated: false,
    });
  }

  static allGrantedUsers(token) {
    return fetch(`${config.apiHost}/api/v1/me/users`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static allPackages(token, userUuid, filters) {
    const queryString = Object.keys(filters || {})
      .map((key) => `${key}=${filters[key]}`)
      .join('&');
    return fetch(
      `${config.apiHost}/api/v1${
        userUuid ? '/users/' + userUuid : ''
      }/packages${queryString ? `?${queryString}` : ''}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  static getPackage(token, userUuid, packageUuid) {
    return fetch(
      `${config.apiHost}/api/v1${
        userUuid ? '/users/' + userUuid : ''
      }/packages/${packageUuid}/page`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  static getOffer(token, userUuid, packageUuid, offerUuid) {
    return fetch(
      `${config.apiHost}/api/v1${
        userUuid ? '/users/' + userUuid : ''
      }/packages/${packageUuid}/offers/${offerUuid}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  static updateEnvelopeStatus(
    token,
    userUuid,
    packageUuid,
    docusignAccountId,
    envelopeId,
    status
  ) {
    return fetch(
      `${config.apiHost}/api/v1${
        userUuid ? '/users/' + userUuid : ''
      }/packages/${packageUuid}/envelopes/${docusignAccountId}/${envelopeId}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        json: {
          status,
        },
      }
    );
  }

  static getPackageLink(token, packageSlug) {
    return fetch(`${config.apiHost}/api/v1/link/${packageSlug}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      redirectUnauthenticated: false,
    });
  }

  static allContacts(token, userUuid) {
    return fetch(
      `${config.apiHost}/api/v1/${
        userUuid ? 'users/' + userUuid : 'me'
      }/contacts`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  static allSessions(token) {
    return fetch(`${config.apiHost}/api/v1/me/sessions`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static allUserCharges(token, userUuid) {
    return fetch(
      `${config.apiHost}/api/v1/${
        userUuid ? 'users/' + userUuid : 'me'
      }/billing/invoice`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  static allBrokerageCharges(token, brokerageUuid) {
    return fetch(
      `${config.apiHost}/api/v1/brokerages/${brokerageUuid}/billing/invoice`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
}
